import React, { useEffect, useState } from "react"

// Components
import CustomButton from "../CustomButton"
import CustomDropDown from "../CustomDropdown"
import {GetAPIData, Building_API} from "../common/agentAPI"
import {saleType, minBedroomOptions} from "../common/propertyUtils"
import FilterSearch from "components/SearchResults/FilterSearch"

const DashboardFilter = ({status, setStatus, setActiveButton, agentArea,initialAgentArea, setAgentArea, setBedrooms, setBuildingType, setPageNo}) => {     
    const[build_type, setBuilding_Type] = useState([]);
    const[status_temp, setStatusTemp] = useState(status);
    const[bedroom_temp, setBedroomsTemp] = useState('');
    const[buildtype_temp, setBuildingTypeTemp] = useState('');

    useEffect(() => {
        var building_api = Building_API+`?request_from=website&_limit=-1&status=${status_temp}`;

        GetAPIData(building_api).then(async apiRes => {
            
            var property_type = [{display:"All Buildings", value:"All"}];
            if(apiRes?.success === true){
                apiRes?.results?.length > 0 && apiRes?.results.map((item) =>{
                    property_type.push({display:item.building_name, value:item._id});
                })
                setBuilding_Type(property_type)
            }
        });
        if(status_temp === 'For Sale') { setActiveButton(0) }
        setAgentArea(initialAgentArea)

    }, [status_temp])

    const FilterSearch = () => {
        setStatus(status_temp);
        setBedrooms(bedroom_temp);
        setBuildingType(buildtype_temp);
        setPageNo(1);
        if(status_temp === 'For Sale') { setActiveButton(0) }
        setAgentArea(initialAgentArea)
    }
    
    return (
        <div className="filter-form-wrapper">
            <CustomDropDown
                name="saleType"
                defaultOption={ saleType[ 0 ] }
                options={ saleType }
                variant="black"
                onChangeCallback={setStatusTemp}
            /> 
            
            {build_type.length > 0 && 
            <CustomDropDown
                name="propertyType"
                defaultOption={ build_type[ 0 ] }
                options={ build_type }
                variant="black"
                onChangeCallback={setBuildingTypeTemp}
            />
            }

            <CustomDropDown
                name="minBedroomOptions"
                defaultOption={ minBedroomOptions[ 0 ] }
                options={ minBedroomOptions }
                variant="black"
                onChangeCallback={setBedroomsTemp}
            />

            <CustomButton
                type="button"
                variant="green"
                onClick={FilterSearch}
                value="SEARCH"
            />
        </div>
    )
}

export default DashboardFilter