import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
// Components
import CustomButton from "../CustomButton";
import { PostAPIData } from "../common/agentAPI";

const AvailableButtons = ({ setCurrentWeek, setStatus, status, currentWeek, agentArea, setAgentArea, activeButton, setActiveButton, initialAgentArea
}) => {
  const buttonData = [
    { label: "My Listings", variant: agentArea ? "green" : "outline" },
    { label: "AVAILABLE WEEKLY LETTINGS SHEET", variant: "outline" },
    { label: "FULL AVAILABLE LETTINGS LIST", variant: "outline" }
  ];

  const[showDownloadButtons, setShowDownloadButtons] = useState(false);
 

  let date_today = new Date();
  let first_day_of_the_week = new Date(
    date_today.setDate(date_today.getDate() - date_today.getDay())
  );
  const download_api = `${process.env.GATSBY_STRAPI_FORM_URL}/property-uploader/api/pu-property/downloadpdf`;
  const downloadAssets = () => {
    PostAPIData(`${download_api}`, {
      property_search_type: status,
      type: currentWeek ? "week" : "all",
      date: currentWeek ? dateFormat(first_day_of_the_week, "yyyy-mm-dd") : null,
      town: agentArea ? agentArea : "all",
    }).then(async (apiRes) => {
      if (apiRes?.success === true && apiRes?.path) {
        window.open(apiRes?.path);
      }
    });
  };

  const handleButtonClick = (index) => {
    if (index === 1 || index === 2) {
      setStatus("To Rent");
      setShowDownloadButtons(true);
    } else {
      setStatus(status);
      setShowDownloadButtons(false);
    }
    setActiveButton(index);
    setAgentArea(index === 0 ? initialAgentArea : "");
    setCurrentWeek(index === 1 ? true : false);
  };

  return (
    <div className="button-wrapper">
      {buttonData.map((button, index) => (
        (status === "To Rent" || index === 0) && (
            <CustomButton
            key={index}
            variant={index === activeButton ? "green" : button.variant}
            onClick={() => handleButtonClick(index)}
            value={button.label}
            /> 
        )
      ))}
      {(showDownloadButtons && activeButton !== 0) && (
        <CustomButton
                variant="outline"
                onClick={ () => {
                    downloadAssets()
                } }
                value="Download"
            />
      )}
    </div>
  );
};

export default AvailableButtons;